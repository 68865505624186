"use client";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { Icons } from "@/components/ui/data-display/icons";

import { useAuthProfileListSuspense as useAuthProfileList } from "architecture/src/shared/api/endpoints/auth/auth";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/data-display/avatar";
import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button/button";
import { Stack } from "@/components/ui/layout/stack";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/navigation/dropdown-menu";

import { Suspense } from "react";

import { InputSearch, Notifications } from "./_components";

export function HeaderAuthenticated() {
  return (
    <Stack direction="row" spacing="xs" align="center">
      <>
        <Button variant="link" size="sm"></Button>

        {/* InputSearch */}
        <InputSearch />
        <Link href="/dashboard/lista-de-lectura" className="hidden md:block">
          <Icons.bookmark color="#C7B6FC" className="[&>path]:stroke-primary" />
        </Link>

        {/* Notifications */}
        <Notifications />

        <DropdownMenu>
          <DropdownMenuTrigger>
            <Suspense fallback={<>Loading UserInformation...</>}>
              <UserInformation />
            </Suspense>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>Mi cuenta</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild className="cursor-pointer">
              <Link href="/dashboard/perfil">Perfil</Link>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                signOut({
                  callbackUrl: "/",
                })
              }
              className="cursor-pointer"
            >
              Cerrar sesión
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    </Stack>
  );
}

function UserInformation() {
  const { data: user } = useAuthProfileList();
  const { avatar, firstName, lastName, company } = user;
  return (
    <Stack direction="row" spacing="xs">
      <Stack className="hidden md:flex" align="right" justify="center">
        <Typography className="capitalize" weight="semibold">
          {firstName} {lastName}
        </Typography>
        {company && (
          <Typography variant="caption" className="capitalize" color="muted">
            {company?.name}
          </Typography>
        )}
      </Stack>
      <Stack direction="row">
        <Avatar className="w-12 h-12 rounded-dot">
          <AvatarImage src={avatar ?? ""} />
          <AvatarFallback className="rounded-none">{`${firstName[0]}${lastName[0]}`}</AvatarFallback>
        </Avatar>
        {company && (
          <Avatar className="hidden w-12 h-12 rounded-dot rounded-dot--inverted md:flex">
            <AvatarImage
              src={company.logo ?? ""}
              alt={company.name}
              className="object-cover"
            />

            <AvatarFallback>{company.name[0]}</AvatarFallback>
          </Avatar>
        )}
      </Stack>
    </Stack>
  );
}

UserInformation.displayName = "UserInformation";

HeaderAuthenticated.displayName = "Header.Authenticated";
