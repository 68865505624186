import { useToast } from "@/components/ui/feedback/toast/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const FormSchema = z.object({
  query: z.string().min(3, { message: "Ingresa al menos 3 caracteres" }),
});

type SearchData = z.infer<typeof FormSchema>;

export const useSearch = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { toast } = useToast();

  const query = searchParams?.get("q");

  const isSearchPage = pathname === "/dashboard/search";

  const form = useForm<SearchData>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      query: "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    form.reset({
      query: query || "",
    });
  }, [isSearchPage, query, form]);

  useEffect(() => {
    const errors = form.formState.errors;
    if (errors.query) {
      toast({
        title: "Error de búsqueda",
        description: errors.query.message,
        variant: "warning",
      });
    }
  }, [form.formState.errors, toast]);

  const handleSearch = (values: SearchData) => {
    router.push(`/dashboard/search?q=${values.query}`);
  };

  const handleReset = () => {
    form.reset({
      query: "",
    });
  };

  return { form, handleSearch, handleReset };
};
