"use client";

import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { XIcon } from "lucide-react";
import { useEffect, useState } from "react";

import {
  getNotificationListQueryKey,
  useNotificationList,
  useNotificationReadAllPartialUpdate,
} from "architecture/src/shared/api/endpoints/notification/notification";

import { Badge } from "@/components/ui/data-display/badge";
import { Icons } from "@/components/ui/data-display/icons";
import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button";
import { Stack } from "@/components/ui/layout/stack";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/navigation/dropdown-menu";
import { NotificationsItem } from "./notifications.item";
import { NotificationsSkeleton } from "./notifications.skeleton";
import { ScrollArea } from "@/components/ui/feedback/scroll-area";

function NotificationsBadge({ children }: { children: number }) {
  const count = children > 9 ? "9+" : children.toString();
  return (
    <Badge variant="destructive" size="notification">
      {/* TODO: Get notifications count */}
      {count}
    </Badge>
  );
}

export function Notifications() {
  const [page] = useState(1);
  const [open, setOpen] = useState(false);
  const page_size = 20;
  const {
    data: notifications,
    isLoading,
    isError,
  } = useNotificationList(
    {
      page,
      page_size,
    },
    {
      query: {
        placeholderData: keepPreviousData,
      },
    },
  );

  const queryClient = useQueryClient();

  const { mutate: readAllMutate } = useNotificationReadAllPartialUpdate({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getNotificationListQueryKey({ page, page_size }),
        });
        setOpen(false);
      },
    },
  });

  const { results } = notifications || {};

  const hasNotifications = (results?.length || 0) > 0;

  useEffect(() => {
    if (!hasNotifications) {
      setOpen(false);
    }
  }, [hasNotifications]);

  if (isLoading) return <Notifications.Skeleton />;

  if (isError) return null;

  const handleReadAll = () => {
    readAllMutate();
  };

  return (
    <>
      <DropdownMenu onOpenChange={setOpen} open={open}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            rounded="full"
            size="icon"
            disabled={!hasNotifications}
            className="relative"
          >
            {notifications ? (
              <>
                {notifications.meta?.totalRecords > 0 ? (
                  <Stack className="absolute top-0 right-0 pointer-events-none">
                    <Notifications.Badge>
                      {notifications.meta?.totalRecords}
                    </Notifications.Badge>
                  </Stack>
                ) : null}
              </>
            ) : null}
            <Icons.notification />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-auto max-w-full md:max-w-md md:mx-0">
          <DropdownMenuLabel>
            <Stack direction="row" align="center" justify="spaceBetween">
              <Typography weight="semibold" variant="h5">
                Notificaciones
              </Typography>
              <Button
                variant="ghost"
                rounded="full"
                size="icon"
                onClick={() => setOpen(false)}
              >
                <XIcon className="w-4 h-4" />
              </Button>
            </Stack>
          </DropdownMenuLabel>
          <DropdownMenuSeparator className="mx-2" />
          {results && (
            <Stack>
              <Stack
                align="center"
                direction="row"
                justify="spaceBetween"
                className="px-4"
              >
                <Typography weight="semibold" variant="caption">
                  ({notifications?.meta.totalRecords}){" "}
                  {notifications?.meta.totalRecords === 1 ? (
                    <>notificación</>
                  ) : (
                    <>notificaciones</>
                  )}
                </Typography>
                <Button variant="link" className="px-0" onClick={handleReadAll}>
                  Marcar todo como leído
                </Button>
              </Stack>
              <ScrollArea>
                <Stack spacing="sm">
                  {results.map((notification) => (
                    <Stack
                      key={`${notification.id}-${notification.text}`}
                      className="pl-6"
                    >
                      <Notifications.Item
                        {...notification}
                        page={page}
                        page_size={page_size}
                        closeMenu={() => setOpen(false)}
                      />
                    </Stack>
                  ))}
                </Stack>
              </ScrollArea>
            </Stack>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

Notifications.displayName = "Notifications";

Notifications.Skeleton = NotificationsSkeleton;
Notifications.Badge = NotificationsBadge;
Notifications.Item = NotificationsItem;
