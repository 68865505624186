"use client";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";

import Brand from "public/static/images/shared/brand.svg";

import { Stack } from "@/components/ui/layout/stack";
import { Suspense } from "react";
import { HeaderAnonymous } from "./header.anonymous";
import { HeaderAuthenticated } from "./header.authenticated";
import { HeaderSkeleton } from "./header.skeleton";

interface HeaderProps {
  children: React.ReactNode;
}

export function Header({ children }: HeaderProps) {
  return (
    <motion.header className="sticky top-0 z-20 flex items-center h-16 border-none md:h-20 bg-background text-foreground">
      <div className="container flex justify-between md:justify-start items-center w-full px-4">
        {/* Left group */}

        <div className="flex items-center gap-10">
          {/* Brand */}
          <Link className="w-8 h-8 md:w-auto md:h-auto" href="/">
            <Image
              src={Brand}
              alt="Dapper"
              className="w-auto h-8 max-w-[25vw] md:h-12"
              sizes={"(min-height: 768px) 40px, 48px"}
            />
          </Link>
        </div>

        {/* Right group */}
        <Stack direction="row" className="md:ml-auto">
          <Suspense fallback={<Header.Skeleton />}>{children}</Suspense>
        </Stack>
      </div>
    </motion.header>
  );
}

Header.Authenticated = HeaderAuthenticated;
Header.Anonymous = HeaderAnonymous;
Header.Skeleton = HeaderSkeleton;
