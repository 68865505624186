"use client";

import { XIcon } from "lucide-react";

import { Icons } from "@/components/ui/data-display/icons";
import { Button } from "@/components/ui/inputs/button";
import { Input } from "@/components/ui/inputs/input";
import { Stack } from "@/components/ui/layout/stack";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/inputs/form";
import { cn } from "@/src/lib/utils";
import { useSearch, useToggleSearch } from "../_hooks";

const SearchButton = ({
  open,
  handleToggleOpen,
  ...props
}: {
  open: boolean;

  handleToggleOpen: () => void;
}) => (
  <Button
    variant="ghost"
    type="submit"
    onClick={handleToggleOpen}
    className={`z-10 transition-all duration-500 ease-in-out relative rounded-none w-6 md:w-9 px-0 h-full md:hidden opacity-100 ${open && "opacity-0"}`}
    {...props}
  >
    <Icons.search color="#5723F5" />
  </Button>
);

export function InputSearch() {
  const { open, isMobile, refSearch, handleToggleOpen } = useToggleSearch();
  const { form, handleSearch, handleReset } = useSearch();

  return (
    <Form {...form}>
      {isMobile && open && (
        <div className="fixed top-16 left-0 h-[calc(100%-4rem)] w-full bg-black/80 transition-all duration-500 ease-in-out md:hidden pointer-events-none opacity-100" />
      )}

      <Stack
        onSubmit={form.handleSubmit((data) => {
          handleSearch(data);
          handleToggleOpen();
        })}
        direction="row"
        spacing="md"
        align="center"
        ref={refSearch}
        component="form"
        className={`md:mx-2 transition-all border-none duration-500 ease-in-out fixed md:relative left-0 top-10 md:top-0 bg-white md:bg-transparent w-full md:w-auto md:p-0 py-2 px-4 pointer-events-none opacity-0 ${isMobile ? open && "top-16 pointer-events-auto opacity-100" : "top-0 pointer-events-auto opacity-100"}`}
      >
        <Stack className="relative flex-1" direction="row">
          <FormField
            control={form.control}
            name="query"
            render={({ field }) => (
              <Stack component={FormItem} className="relative flex-1 space-y-0">
                <FormLabel className="sr-only">Búsqueda</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Buscar..."
                    className={cn(
                      form.formState.errors.query && "border-destructive",
                      "w-full md:w-60 lg:w-72 rounded-r-none",
                    )}
                    {...field}
                  />
                </FormControl>
                {/* Reset form */}
                {form.getValues("query").length > 0 && (
                  <div
                    onClick={() => handleReset()}
                    role="button"
                    className="absolute inset-y-0 right-0 flex items-center justify-center transition-colors cursor-pointer aspect-square text-muted-foreground hover:text-secondary-foreground"
                  >
                    <XIcon />
                  </div>
                )}
              </Stack>
            )}
          />
          <Button className="px-0 rounded-l-none border-primary aspect-square">
            <Icons.search className="text-white" />
          </Button>
        </Stack>
      </Stack>
      <SearchButton open={open} handleToggleOpen={handleToggleOpen} />
    </Form>
  );
}
